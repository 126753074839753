const Panel = Object.freeze({
  BASIC: 'Basic',
  ADVANCED_BASELINE: 'Advanced (Baseline)',
  ADVANCED_FOLLOWUP: 'Advanced (Followup)',
  ATHLETE: 'Athlete',
  TESTOSTERONE: 'Testosterone',
  BIOMETRICS: 'Biometrics',
  ALLERGY: 'Allergy',
  THYROID: 'Thyroid',
  GALLERI: 'Galleri',
  CHOLESTEROL_THERAPY: 'Cholesterol Therapy',
  HEART_HEALTH: 'Heart Health',
  CHOLESTEROL_BALANCE: 'Cholesterol Balance',
  FERTILITY: 'Fertility',
  OMEGA: 'Omega',
  NMR_LIPOPROFILE: 'NMR Lipoprofile',
  CUSTOM: 'Custom'
})

export default Panel;
